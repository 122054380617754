
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { defineComponent } from "vue";
import { addLabelValue, tree } from "@/utils/tools";
import { ElMessage, ElMessageBox } from "element-plus";
import { client } from "@/client";
export default defineComponent({
  name: "article-index",
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
    };
  },
  data: () => {
    return {
      keywords: "",
      cid: 0,
      cur: 1,
      categorySelected: [] as any[],
      category: [] as any[], //当前所有栏目
      tableData: [] as any[],
      multipleSelection: [] as any[],
      count: 0,
      loading: true,
    };
  },
  computed: {},
  created() {
    let cur = this.$route.query.cur as string;
    let keywords = this.$route.query.keywords as string;
    this.cur = parseInt(cur);
    this.keywords = keywords as string;
    this.queryCategory();
    this.search();
  },

  watch: {
    $route(to, from) {
      console.log(to);
      if (to.name == "article-index") {
        let { cur, cid, keywords } = to.query;
        this.cur = +cur;
        this.cid = +cid;
        this.keywords = keywords;
        this.search();
      }
    },
  },
  methods: {
    //清空搜索
    clearSearch() {
      this.$router.replace({
        name: "article-index",
        query: { cur: 1, cid: 0, keywords: "" },
      });
    },

    doSearch() {
      this.$router.replace({
        name: "article-index",
        query: { cur: this.cur, cid: this.cid, keywords: this.keywords },
      });
      this.search();
    },

    //查询
    async search() {
      try {
        let res = await client.callApi("article/List",{cur:this.cur,key:this.keywords,cid:this.cid});
        if (res.isSucc) {
          this.tableData = [...res.res.list];
          this.count = res.res.count;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //翻页
    handleCurrentChange(e: number) {
      this.loading = true;
      this.cur = +e;
      this.doSearch();
    },

    toggleSelection(rows: any[]) {
      let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row) => {
          refs.toggleRowSelection(row);
        });
      } else {
        refs.clearSelection();
      }
    },

    handleSelectionChange(val: any[]) {
      this.multipleSelection = val;
    },

    //查询
    async queryCategory() {
      try {
        let res = await client.callApi("category/Find",{});
        if (res.isSucc) {
          let dataTree = addLabelValue(tree(res.res.list));
          this.category = [...dataTree];
        }
      } catch (error) {
        console.log(error);
      }
    },

    //选择栏目
    handleChange(e: any[]) {
      this.cid = e[e.length - 1];
    },

    //跳转到编辑
    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "article-edit", params: { id: id } });
    },

    delSome() {
      let ids = this.multipleSelection.map((item) => {
        return parseInt(item.id);
      });
      ElMessageBox.confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
        let res = await client.callApi("article/Del",{ids:ids});
        if (res.isSucc) {
            ElMessage({
              message: "删除成功 ^_^",
              type: "success",
            });
            this.search();
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //删除文章
    async handleDel(e: { id: any; }) {
      let id = e.id;
      try {
        let res = await client.callApi("article/Del",{ids:[id]});
        if (res.isSucc) {
          ElMessage({
            message: "文章删除成功 ^_^",
            type: "success",
          });
          this.search();
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleClick(e:any) {
      console.error(e);
    }
  },
  onBeforeUnmount(e:any) {
    console.error(e);
  },
});
